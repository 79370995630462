import { defineStore } from 'pinia'


export const useShowClassIfy = defineStore('showClassIfy', {
    state: () => {
        return {
            isShow: false
        }
    },
    actions: {
        setShow() {
            this.isShow = true
        }

    }
})